"use client";

import "react-toastify/dist/ReactToastify.css";
import React, { ReactNode } from "react";
import Script from "next/script";
import { GlobalStyles } from "@/styles/styles.config";
import StyledComponentsRegistry from "./registry";
import { ParallaxProvider as ParallaxProviderComp } from "react-scroll-parallax";
import { ToastContainer } from "react-toastify";

const ParallaxProvider = ({ children }: { children: ReactNode }) => {
  return <ParallaxProviderComp>{children}</ParallaxProviderComp>;
};

export const CookiesProvider = () => {
  const isDev = process.env.NEXT_PUBLIC_ENV === "dev";

  if (isDev) {
    return null;
  }

  return (
    <Script
      id="ref-script"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        // Get referrer, source, UTM parameters, etc and store in cookie
        __html: `
            (function() {
              // Function to check if a specific cookie exists
              function doesCookieExist(name) {
                  return document.cookie.split(';').some(function(item) {
                      return item.trim().indexOf(name + '=') == 0;
                  });
              }
          
              // Function to create a flat object from URLSearchParams
              function getQueryParams() {
                  var params = new URLSearchParams(window.location.search);
                  var queryParams = {};
                  for (const [key, value] of params.entries()) {
                      queryParams[key] = value;
                  }
                  return queryParams;
              }
          
              // Check if the 'cn-u-d' cookie already exists
              if (!doesCookieExist('cn-u-d')) {
                  // Get the referrer
                  var referrer = document.referrer || 'direct';
          
                  // Get the query parameters
                  var queryParams = getQueryParams();
          
                  // Create a flat object with referrer and query parameters
                  var userData = Object.assign({ referrer: referrer, 'landing-page': (window.location.href || '').split('?')[0] }, queryParams);
          
                  // Store the object in a cookie named 'cn-u-d'
                  document.cookie = "cn-u-d=" + encodeURIComponent(JSON.stringify(userData)) + ";path=/;";
              }
            })();
          `,
      }}
    />
  );
};

export const GoogleAnalyticsProvider = () => {
  const isDev = process.env.NEXT_PUBLIC_ENV === "dev";

  if (isDev) {
    return null;
  }

  return (
    <Script
      id="gtm-script"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-WJGB8PP');`,
      }}
    ></Script>
  );
};

export const Providers = ({ children }: { children: ReactNode }) => {
  return (
    <StyledComponentsRegistry>
      <GlobalStyles />
      <ParallaxProvider>
        <GoogleAnalyticsProvider />
        <CookiesProvider />
        <ToastContainer />
        {children}
      </ParallaxProvider>
    </StyledComponentsRegistry>
  );
};
